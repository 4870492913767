import { Link } from 'react-router-dom';
import { gsap } from "gsap";
import React, { useEffect, useRef, useState } from "react";
import { Player } from '@lottiefiles/react-lottie-player';
import Component1 from './PracticeComponents/Component1';
import Component10 from './PracticeComponents/Component10';
import bg from './jsons/bga.json';
import Component5 from './PracticeComponents/Component5';
import Component6 from './PracticeComponents/Component6';
import Component8 from './PracticeComponents/Component8';
import Component82 from './PracticeComponents/Component82';
import Quadraticeqn from './PracticeComponents/Quadraticeqn';
import './PracticeComponents/Component.css';
import './auth.css';
import { updateScore } from './auth'; // Import the updateScore function
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc,updateDoc,onSnapshot } from 'firebase/firestore';
import failedlevel1 from './jsons/failedlevel1.json';
import passedlevel1 from './jsons/passedlevel1.json';
import questiontag from './jsons/qn1.json';
import namescore from './jsons/name&score.json';
import Componentx from './PracticeComponents/Componentx';

import nextbutton from './jsons/nextbtn.json';
import back from './jsons/goback.json';
import tryagain from './jsons/wrongmove.json';
import correct from './jsons/correct1.json';
import Signup from './Signup';
import Login from './Login';
import newuser from './jsons/newuser.json';
import user1 from './jsons/level1user.json';
import user2 from './jsons/level2user.json';
import user3 from './jsons/level3user.json';
import user4 from './jsons/level4user.json';
import Duplicator from './PracticeComponents/Duplicator';
import falling from './jsons/fallingoff.json';
import passedto from './jsons/passedonto.json';
import Component5mob from './PracticeComponents/Component5mob';
import CircularButton from "./bootsrap elements/CircularButton";
import CircularButtonback from "./bootsrap elements/CircularButtonback";
import { Card } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';

const auth = getAuth();
const db = getFirestore();
//const currentUser = auth.currentUser;
//const currentUserId = currentUser.uid;

const MathTraining = () => {
    const bgref = useRef(null);
    const questionref = useRef(null);
    const answerref = useRef(null);
    const profileref = useRef(null);
    const button1ref = useRef(null);
    
    const button2ref = useRef(null);

    const verdictref = useRef(null);

    const nextbtnref = useRef(null);
    
    const [nextbtn, setnextbtn] = useState(false);
    const [dataFromChild, setDataFromChild] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [initial, setinitial] = useState(9);

    const [questionno, setquestionno] = useState(1);
    const [authenticate, setauthenticate] = useState(true);
    const [showLogin, setShowLogin] = useState(false);
    const [showSignup, setShowSignup] = useState(false);
    const [M1, setM1] = useState(0);
    const [username, setUsername] = useState('');
    const [score, setscore] = useState('');
    const [fail, setfail] = useState('');
    const [unloggedscore, setunloggedscore] = useState(0);
    const [unloggedfail, setunloggedfail] = useState(0);
    const [userUID, setUserUID] = useState(null); // Store the user UID
    const [profilebox, setprofilebox] = useState(newuser); // Store the user UID
    const [level, setLevel] = useState('level1');
    const [verdict, setverdict] = useState(false);  
    const [passorfail, setpassorfail] = useState(passedlevel1);
    const [completed, setcompleted] = useState(false);

    
useEffect(()=> {
    const fetchUserData = async (user) => {
        try {
            console.log('Fetching user data for UID:', user.uid); // Debugging line
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            if (userDoc.exists()) {
                //setscore(userDoc.data().M1);
                if(userDoc.data().Level1 >0){
                    setLevel('level2')
                }
              
                console.log('Level 2 achieved', userDoc.data().name); // Debugging line
            } else {
                console.error('Error with levels');
            }
        } catch (error) {
            console.error('offline, cant fetch level:', error);
           
        }
    };
})

   
useEffect(()=> {
    const fetchUserData = async (user) => {
        try {
            console.log('Fetching user data for UID:', user.uid); // Debugging line
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            if (!userDoc.exists()) {
                //setscore(userDoc.data().M1);
               setprofilebox(newuser);
            }
        } catch (error) {
            setprofilebox(newuser);

            
        }
    };
})

    useEffect(() => {
        const fetchUserData = async (user) => {
            try {
                console.log('Fetching user data for UID:', user.uid); // Debugging line
                const userDoc = await getDoc(doc(db, 'users', user.uid));
                if (userDoc.exists()) {
                    //setscore(userDoc.data().M1);
                    setfail(userDoc.data().Mn);
                    setprofilebox(user1);

                    setUsername(userDoc.data().name);
                    setUserUID(user.uid); // Set the user UID
                    console.log('Username fetched:', userDoc.data().name); // Debugging line
                } else {
                    console.error('No such document!');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setUsername('new user'); // Set default username if there's an error
                console.log('unlogged score',unloggedscore);
                //here is where I want it
                setprofilebox(newuser);
                setscore(unloggedscore);
                setfail(unloggedfail);
            }
        };

        onAuthStateChanged(auth, (user) => {
            if (user) {
                setauthenticate(false);
                fetchUserData(user);
            } else {
                setauthenticate(true);
            }
        });
    }, [unloggedscore, unloggedfail]);
    
    useEffect(() => {
        let unsubscribe;
        if (userUID) {
            const userDocRef = doc(db, 'users', userUID);
            unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    setscore(docSnapshot.data().M1 || 0);
                    setfail(docSnapshot.data().Mn || 0);
                } else {
                    console.error('No such document!');
                }
            });
        }
    
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [userUID]);

    useEffect(() => {
        if(score<4){
           // setprofilebox(user1);

        }
        if (score >= 4 && score<9) {
            const updateLevelAndM1 = async () => {
                try {
                    setLevel('level2');
                    setprofilebox(user2);
                    setpassorfail(passedlevel1);
                    setTimeout(() => {
                        setverdict(true);
                    }, 500);
                    setTimeout(() => {
                        setverdict(false);
                    }, 2500);
                    setprofilebox(user2);
                    await updatelevel1(score);
                    await updateM1InDatabase(0);
                } catch (error) {
                    console.error('Error updating Level1 or M1:', error);
                }
            };
            updateLevelAndM1();
        } else if(score > 8 && score<11){
            setLevel('level3');
            setprofilebox(user3);
            console.log('level3');

        }
        else if(score>11){
            setLevel('level4');
            setprofilebox(user4);
            console.log('level4');

        }
        
        
    }, [score]);


    useEffect(() => {
        if (questionno === 4 && level==='level1') {
            setTimeout(() => {
                setpassorfail(failedlevel1);
                setverdict(true);
            }, 500);

            setTimeout(() => {
                setverdict(false);
            }, 2500);

        }
    }, [questionno, level]);

    const handleSignOut = (signedout) => {
        if (signedout) {
            setauthenticate(true);
        }
    };


    const updatelevel1 = async (score) => {
        try {
          const userDocRef = doc(db, 'users', userUID);
          await updateDoc(userDocRef, { Level1: score });
          console.log('Level1 updated successfully');
        } catch (error) {
          console.error('Error updating Level1:', error);
        }
      };

    const handleLoginSuccess = () => {
        setauthenticate(false);
    };

    const handleSignupSuccess = () => {
        setShowSignup(false);
        setShowLogin(true);
    };
    
    const prevquestion = () => {
        if(questionno>1){

            setquestionno(questionno - 1);
            console.log('added');
        }
      
    };

    const nextquestion = () => {
        if(questionno>0 && questionno<20){

            setquestionno(questionno + 1);
            console.log('added');
            setnextbtn(false);
        }
       
    };

    const incrementM1 = () => {
        setM1(prevM1 => prevM1 + 1);
        updateM1InDatabase(M1);
    };
    const hoverbutton = (value) => {
        if(value==='button1'){
            gsap.to(button1ref.current, { scale: 1.2, duration:0.5, delay:0,ease:'power4.inOut' });


        }
        else if(value==='button2'){

            gsap.to(button2ref.current, { scale: 1.2, duration:0.5, delay:0,ease:'power4.inOut' });

        }
    };
    const hoverbackbutton = (value) => {
        if(value==='button1'){
            gsap.to(button1ref.current, { scale: 1, duration:0.5, delay:0,ease:'power4.inOut' });


        }
        else if(value==='button2'){

            gsap.to(button2ref.current, { scale: 1, duration:0.5, delay:0,ease:'power4.inOut' });

        }
    };
    const updateM1InDatabase = async (newM1Value) => {
        try {
            const response = await fetch('/api/update-m1', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ M1: newM1Value }),
            });

            if (!response.ok) {
                throw new Error('Failed to update M1 in the database');
            }

            const data = await response.json();
            console.log('M1 updated successfully:', data);
        } catch (error) {
            console.error('Error updating M1 in the database:', error);
        }
    };
    const handleDataFromChild = (data) => {
        setDataFromChild(data);
        if(data){
            setnextbtn(true);
        }
        else{
            setnextbtn(false)
        }
      };

    const buttonStyle2 = {
        margin: "10px",
        padding: "20px 20px",
        borderRadius: "50px",
        backgroundColor: "#8b0000",
        color: "white",
        border: "none",
        cursor: "pointer",
        fontFamily: "Helvetica",
        fontWeight: "bold",
        fontSize: "26px"
      };
    const buttonStyle = {
        margin: "10px",
        padding: "20px 20px",
        borderRadius: "50px",
        backgroundColor: "#061FBE",
        color: "white",
        border: "none",
        cursor: "pointer",
        fontFamily: "Helvetica",
        fontWeight: "bold",
        fontSize: "26px"
      };
      useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    return (
        <div >
            {isMobile? (

<div style={{height:"100vh",width:"100vw",backgroundColor:"#8AC6FF"}} >
{authenticate && (
    <div className='auth-overlay'>
        {!showLogin && !showSignup && (
            <div style={{display:'flex'}}>
                <div ref={button1ref} onMouseEnter={()=> hoverbutton('button1')} onMouseLeave={()=> hoverbackbutton('button1')}>
                <button style={buttonStyle} onClick={() => setShowLogin(true)}>Login</button>

                    </div>
                    <div ref={button2ref} onMouseEnter={()=> hoverbutton('button2')}  onMouseLeave={()=> hoverbackbutton('button2')}>           
             <button style={buttonStyle2} onClick={() => setShowSignup(true)}>Signup</button>

                    </div>
            </div>
        )}
        {showSignup && <Signup onSignupSuccess={handleSignupSuccess} />}
        {showLogin && <Login onLoginSuccess={handleLoginSuccess} />}
    </div>
)}

{!authenticate && (
    <div style={{position:'fixed'}}>
        <div className='headingquestions'>
            <Player ref={questionref} autoplay={true} loop={false} src={questiontag} keepLastFrame={true} />
        </div>


        <div style={{ position:'fixed',left:'320px',top:'40px',fontSize: "30px", fontFamily: "Helvetica", fontWeight: 'bold', color: "darkblue" ,userSelect:'none'}}>
            {questionno}
        </div>
       

        {questionno === 1 && (
           <div style={{zIndex:6,position:'fixed',left:'10px',top:'10px'}}>
           <Component1 variableValue="1" oneValue="6" fiveValue="4" sign1="-" sign2="+"  sendDataToParent={handleDataFromChild}/>
           </div>
        )}

        {questionno === 2 && (
            <div style={{zIndex:6,position:'fixed',left:'10px',top:'10px'}}>
                <Component1 variableValue="1" oneValue="6" fiveValue="4" sign1="-" sign2="+"  sendDataToParent={handleDataFromChild}/>
                </div>
        )}
        {questionno === 3 && (
            <div style={{zIndex:6,position:'fixed',left:'10px',top:'10px'}}>
                <Component10   variableValue="3" oneValue="2" fiveValue="11" sign1="+" sign2="-"  sendDataToParent={handleDataFromChild} />

            </div>
        )}
        {questionno === 4 && (

           

<div style={{zIndex:6,position:'fixed',right:'30px',top:'10px'}}>
<Component5 C1={5} C2={2} C3={4} C4={3} sign1={"+"} sign2={"+"} sendDataToParent={handleDataFromChild} />
</div>
        )}
         {questionno === 5 && (

           

<div style={{zIndex:6,position:'fixed',left:'10px',top:'10px'}}>
<Component10   variableValue="2" oneValue="1" fiveValue="11" sign1="+" sign2="-"  sendDataToParent={handleDataFromChild} />
</div>
)}


{questionno === 6 &&   (
    <div style={{zIndex:6,position:'fixed',right:'30px',top:'10px'}}>
    <Component5 C1={3} C2={4} C3={2} C4={5} sign1={"+"} sign2={"+"} sendDataToParent={handleDataFromChild} />

            </div>
        )}
      
      {questionno === 7 &&   (
            <div style={{zIndex:6,position:'fixed',left:'10px',top:'10px'}}>
                                <Component6 C1="2" C2="3" C3="1" C4="5" sign1="+" sign2="-" sendDataToParent={handleDataFromChild}  userUID={userUID} 
                                
                            
     
                                 />
                            </div>
                        )}
     {questionno === 8 &&   (
            <div style={{zIndex:6,position:'fixed',left:'10px',top:'10px'}}>
                                <Component8 n1="4" sendDataToParent={handleDataFromChild}  userUID={userUID} 
                                
                           
     
                                 />
                            </div>
                        )}
    
    
    {questionno === 9 &&   (
            <div style={{zIndex:6,position:'fixed',left:'10px',top:'10px'}}>
                                <Component8 n1="4" sendDataToParent={handleDataFromChild}  userUID={userUID} 
                                
            
     
                                 />
                            </div>
                        )}
                        {questionno === 10 &&  (
            <div style={{zIndex:6,position:'fixed',left:'10px',top:'10px'}}>
                                <Component8 n1="16" sendDataToParent={handleDataFromChild}  userUID={userUID} 
                                
            
     
                                 />
                            </div>
                        )}
                        {questionno === 11 &&  (
            <div style={{zIndex:6,position:'fixed',left:'10px',top:'10px'}}>
                                <Component82 C3='2' n1="144" sendDataToParent={handleDataFromChild}  userUID={userUID} 
                                
            
     
                                 />
                            </div>
                        )}
           {questionno === 12 &&  (
            <div style={{zIndex:6,position:'fixed',left:'10px',top:'10px'}}>
                                <Component82 C3='2' n1="144" sendDataToParent={handleDataFromChild}  userUID={userUID} 
                                
            
     
                                 />
                            </div>
                        )}


        <div  style={{ position:'fixed',scale:'4',top:'350px',zIndex: 1,opacity:'0' }}>
            <Player ref={bgref} autoplay={false} loop={false} src={bg} />
        </div>


        <Container fluid className="fixed-bottom bg-light p-3">
        <Row >

{nextbtn && (
  <Col xs={12} md={4} lg={5}  >
    <Card style={{ width: 'auto' }}>
    <Card.Footer className="d-flex justify-content-between">
    <div onClick={prevquestion}>
    <CircularButtonback/>
    </div>
    <div onClick={nextquestion}>
    <CircularButton/>
    </div>
    </Card.Footer>

</Card>
</Col>
)}
</Row >
</Container>








        
      

       
        
    </div>
)}
</div>
            ):(

                <div>
                {authenticate && (
                    <div className='auth-overlay'>
                        {!showLogin && !showSignup && (
                            <div style={{display:'flex'}}>
                                <div ref={button1ref} onMouseEnter={()=> hoverbutton('button1')} onMouseLeave={()=> hoverbackbutton('button1')}>
                                <button style={buttonStyle} onClick={() => setShowLogin(true)}>Login</button>
    
                                    </div>
                                    <div ref={button2ref} onMouseEnter={()=> hoverbutton('button2')}  onMouseLeave={()=> hoverbackbutton('button2')}>           
                             <button style={buttonStyle2} onClick={() => setShowSignup(true)}>Signup</button>
    
                                    </div>
                            </div>
                        )}
                        {showSignup && <Signup onSignupSuccess={handleSignupSuccess} />}
                        {showLogin && <Login onLoginSuccess={handleLoginSuccess} />}
                    </div>
                )}
    
                {!authenticate && (
                    <div className='questionsmainpage'>
                        <div className='headingquestions'>
                            <Player ref={questionref} autoplay={true} loop={false} src={questiontag} keepLastFrame={true} />
                        </div>
    
        
    
                        <div className='answerspace' style={{ position: 'fixed' }}>
                            <Player ref={answerref} autoplay={false} loop={false} src={tryagain} />
                        </div>
                        <div className='questionnumber' style={{ fontSize: "30px", fontFamily: "Helvetica", fontWeight: 'bold', color: "darkblue" ,userSelect:'none'}}>
                            {questionno}
                        </div>
                      
                
                        {questionno === 1 && (
                            <div className='workarea'>
                                <Component1 variableValue="1" oneValue="1" fiveValue="2" sign1="-" sign2="+" sendDataToParent={handleDataFromChild} />
                            </div>
                        )}
    
                        {questionno === 2 && (
                            <div className='workarea'>
                                <Component1 variableValue="1" oneValue="3" fiveValue="5" sign1="-" sign2="+"  sendDataToParent={handleDataFromChild}
                             
                                  />
                            </div>
                        )}
                        {questionno === 3 && (
                            <div className='workarea'>
                                <Component10   variableValue="3" oneValue="2" fiveValue="11" sign1="+" sign2="-"  sendDataToParent={handleDataFromChild}
                          
                                   />
    
                            </div>
                        )}
                        {questionno === 4 && (
    
                           
    
                            <div className='workarea'>
                                <Component5 C1={2} C2={3} C3={1} C4={7} sign1={"+"} sign2={"+"}  sendDataToParent={handleDataFromChild}/>
                            </div>
                        )}
                         {questionno === 5 && (
    
                           
    
    <div className='workarea'>
        <Component5 C1={5} C2={2} C3={4} C4={3} sign1={"+"} sign2={"+"} sendDataToParent={handleDataFromChild} />
    </div>
    )}
               
    
    {questionno === 6 &&   (
                            <div className='workarea'>
                                <Component6 C1="4" C2="2" C3="6" C4="2" sign1="+" sign2="-" sendDataToParent={handleDataFromChild}  userUID={userUID} 
                                
                           
     
                                 />
                            </div>
                        )}
                        {questionno === 7 &&   (
                            <div className='workarea'>
                                <Component6 C1="2" C2="3" C3="1" C4="5" sign1="+" sign2="-" sendDataToParent={handleDataFromChild}  userUID={userUID} 
                                
                            
     
                                 />
                            </div>
                        )}
     {questionno === 8 &&   (
                            <div className='workarea'>
                                <Component6 C1="3" C2="2" C3="4" C4="1" sign1="+" sign2="-" sendDataToParent={handleDataFromChild}  userUID={userUID} 
                                
                           
     
                                 />
                            </div>
                        )}
    
    
    {questionno === 9 &&   (
                            <div className='workarea'>
                                <Component8 n1="4" sendDataToParent={handleDataFromChild}  userUID={userUID} 
                                
            
     
                                 />
                            </div>
                        )}
                        {questionno === 10 &&  (
                            <div className='workarea'>
                                <Component8 n1="16" sendDataToParent={handleDataFromChild}  userUID={userUID} 
                                
            
     
                                 />
                            </div>
                        )}
                        {questionno === 11 &&  (
                            <div className='workarea'>
                                <Component82 n1="144" sendDataToParent={handleDataFromChild}  userUID={userUID} 
                                
            
     
                                 />
                            </div>
                        )}
     {questionno === 12 &&  (
                            <div className='workarea'>
                                <Component82 C3="2" n1="146" sendDataToParent={handleDataFromChild}  userUID={userUID} 
                                
            
     
                                 />
                            </div>
                        )}
                      
    
                        <div className='backgroundpaper' style={{ zIndex: 1,position:'fixed',left:'90px' }}>
                            <Player ref={bgref} autoplay={false} loop={false} src={bg} />
                        </div>
     
    
     {nextbtn && (
    
    <div  onClick={nextquestion} className='nextbutton2'>
    <Player ref={nextbtnref} autoplay={false} loop={false} src={nextbutton} hover={true} />
    </div>
     )}
       
    
    
        
    
                       
     {nextbtn && ( 
                        <div  onClick={prevquestion} style={{position:'fixed', width:'300px',  zIndex:'3',
                top:'620px',
                left:'200px',
                height:'300px'}}>
                            <Player ref={nextbtnref} autoplay={false} loop={false} src={back} hover={true} />
                        </div>
       )}
                        <div className='signoutbox' style={{userSelect:'none',position:'fixed',top:'10px',left:'620px',height:'300px', width:'300px',zIndex:6,fontFamily:'Helvetica'}}>
    
                            
                           
                        </div>
                    </div>
                )}
                </div>

            )}
           
          
        </div>
    );
};

export default MathTraining;
