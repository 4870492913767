import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import './dashboard.css';
import { Player } from '@lottiefiles/react-lottie-player';
import user1 from '../jsons/level1user.json';
import user2 from '../jsons/level2user.json';
import user3 from '../jsons/level3user.json';
import user4 from '../jsons/level4user.json';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, query, orderBy, limit, getDocs, doc, getDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import SignOut from '../Signout';
import newuser from '../jsons/newuser.json';
import NotificationIcon from '../Notified/NotificationIcon'; 
import planet from '../jsons/planet.json';
import home from '../svgs/homebutton.svg';
import sections from '../svgs/sections.svg';
import video from '../svgs/video.svg';
import leadericon from '../svgs/competitive.svg';
import earth from '../jsons/earth.json';


const auth = getAuth();
const db = getFirestore();

const PracticeDashboard = ({ message }) => {
  const [isNotificationViewed, setIsNotificationViewed] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [hoveredChapter, setHoveredChapter] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [score, setscore] = useState(0);
  const [username, setUsername] = useState('new user');
  const [profilebox, setprofilebox] = useState(newuser); // Store the user UID
  const [userUID, setUserUID] = useState(null); // Store the user UID
  const profileref = useRef(null);
  const [leaderboard, setLeaderboard] = useState([]); // State to store the leaderboard data
  const [showLeaderboard, setShowLeaderboard] = useState(false); // To toggle leaderboard visibility
  
  const itemref = useRef(null);
  const [item, setitem] = useState(planet);

  const [authenticate, setauthenticate] = useState(true);
  const verdictref = useRef(null);
  const [questionno, setquestionno] = useState(9);
  const [level, setLevel] = useState('level1');
  const [fail, setfail] = useState('');
  const [verdict, setverdict] = useState(false);  
  const [unloggedscore, setunloggedscore] = useState(0);
  const [unloggedfail, setunloggedfail] = useState(0);
  const [comment, setComment] = useState('');

  const handleMouseEnter = () => {
    if (!isMobile) {
      setExpanded(true);
      setHoveredChapter(1);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setExpanded(false);
      setHoveredChapter(null);
    }
  };

  const button2Style = {
    //margin: "10px",
    padding: "10px 10px",  
     borderRadius: "20px",
     position: 'fixed',
      border: "3px solid black",
      backgroundColor: "white",
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' , // Added box shadow

      left: '10px', 
      fontWeight: 'bolder',
       color: 'black',
        fontSize: '15px',
         userSelect: 'none',


  };
  const leaderboardBoxStyle = {
    padding: '20px',
    top:'220px',
    border: '2px solid black',
    backgroundColor: 'white',
    maxWidth: '300px',
    margin: '15px',
    color:'black',
    borderRadius:'15px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  };
  const leadbuttonStyle = {
    width: '70px',
    position:'relative',
    height: '60px',
    padding: "20px 20px",
    borderRadius: "20px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontFamily: "Helvetica",
    fontWeight: "bold",
    fontSize: "13px",
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
  };
  const buttonStyle = {
    //margin: "10px",
    width:'150px',
    height:'50px',
    padding: "20px 20px",
    borderRadius: "20px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontFamily: "Helvetica",
    fontWeight: "bold",
    fontSize: "13px",
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'  // Added box shadow

  
  };
  const fetchLeaderboard = async () => {
    try {
      // Query to get the top 5 users sorted by score (M1)
      const leaderboardQuery = query(
        collection(db, 'users'),
        orderBy('M1', 'desc'),
        limit(5)
      );
      const querySnapshot = await getDocs(leaderboardQuery);
      
      const leaderboardData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        score: doc.data().M1
      }));
      setLeaderboard(leaderboardData); // Set the leaderboard state
    } catch (error) {
      console.error("Error fetching leaderboard: ", error);
    }
  };
  const handleLeaderboardToggle = () => {
    setShowLeaderboard(!showLeaderboard);
    if (!showLeaderboard) {
      fetchLeaderboard(); // Fetch the leaderboard when it is shown
    }
  };

  const handleCommentSubmit = async () => {
    if (userUID && comment.trim() !== '') {
      try {
        await updateDoc(doc(db, 'users', userUID), {
          comments: comment,
        });
        alert('Comment submitted successfully');
        setComment(''); // Clear the text field
      } catch (error) {
        console.error('Error submitting comment: ', error);
        alert('Error submitting comment');
      }
    } else {
      alert('Please enter a comment');
    }
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const fetchUserData = async (user) => {
      try {
        console.log('Fetching user data for UID:', user.uid); // Debugging line
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          if (userDoc.data().Level1 > 0) {
            setLevel('level2');
          }
          console.log('Level 2 achieved', userDoc.data().name); // Debugging line
        } else {
          console.error('Error with levels');
        }
      } catch (error) {
        console.error('offline, cant fetch level:', error);
      }
    };
  });

  useEffect(() => {
    const fetchUserData = async (user) => {
      try {
        console.log('Fetching user data for UID:', user.uid); // Debugging line
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (!userDoc.exists()) {
          setprofilebox(newuser);
        }
      } catch (error) {
        setprofilebox(newuser);
      }
    };
  });

  useEffect(() => {
    const fetchUserData = async (user) => {
      try {
        console.log('Fetching user data for UID:', user.uid); // Debugging line
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setfail(userDoc.data().Mn);
          setprofilebox(user1);
          setUsername(userDoc.data().name);
          setUserUID(user.uid); // Set the user UID
          console.log('Username fetched:', userDoc.data().name); // Debugging line
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUsername('new user'); // Set default username if there's an error
        console.log('unlogged score', unloggedscore);
        setprofilebox(newuser);
        setscore(unloggedscore);
        setfail(unloggedfail);
      }
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setauthenticate(false);
        fetchUserData(user);
      } else {
        setauthenticate(true);
      }
    });
  }, [unloggedscore, unloggedfail]);

  useEffect(() => {
    let unsubscribe;
    if (userUID) {
      const userDocRef = doc(db, 'users', userUID);
      unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setscore(docSnapshot.data().M1 || 0);
          setfail(docSnapshot.data().Mn || 0);
        } else {
          console.error('No such document!');
        }
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [userUID]);

  useEffect(() => {
    if (score < 4) {
      // setprofilebox(user1);
    }
    if (score >= 4 && score < 9) {
      const updateLevelAndM1 = async () => {
        try {
          setLevel('level2');
          setprofilebox(user2);
          setTimeout(() => {
            setverdict(true);
          }, 500);
          setTimeout(() => {
            setverdict(false);
          }, 2500);
          setprofilebox(user2);
          await updatelevel1(score);
          await updateM1InDatabase(0);
        } catch (error) {
          console.error('Error updating Level1 or M1:', error);
        }
      };
      updateLevelAndM1();
    } else if (score > 8 && score < 11) {
      setLevel('level3');
      setprofilebox(user3);
      console.log('level3');
    } else if (score > 11) {
      setLevel('level4');
      setprofilebox(user4);
      console.log('level4');
    }
  }, [score]);

  useEffect(() => {
    if (questionno === 4 && level === 'level1') {
      setTimeout(() => {
        // setpassorfail(failedlevel1);
        setverdict(true);
      }, 500);

      setTimeout(() => {
        setverdict(false);
      }, 2500);

      // setquestionno(1);
    }
  }, [questionno, level]);

  const handleSignOut = (signedout) => {
    if (signedout) {
      setauthenticate(true);
    }
  };

  const updatelevel1 = async (score) => {
    try {
      const userDocRef = doc(db, 'users', userUID);
      await updateDoc(userDocRef, { Level1: score });
      console.log('Level1 updated successfully');
    } catch (error) {
      console.error('Error updating Level1:', error);
    }
  };

  const updateM1InDatabase = async (newM1Value) => {
    try {
      const response = await fetch('/api/update-m1', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ M1: newM1Value }),
      });

      if (!response.ok) {
        throw new Error('Failed to update M1 in the database');
      }

      const data = await response.json();
      console.log('M1 updated successfully:', data);
    } catch (error) {
      console.error('Error updating M1 in the database:', error);
    }
  };

  return (
    <div>
    
     
    {isMobile ? (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px' }}>
    
    <button className="hamburger-btn" onClick={handleToggle} style={{ marginBottom: '5px' }}>
      <FaBars />
    </button>

    <div className="side-dashboard" style={{ width: expanded ? '200px' : '0px', display: 'flex', flexDirection: 'column', padding: '5px', alignItems: 'center', transition: 'width 0.3s', overflow: 'hidden' }}>
 
  <div className="signoutbox" style={{ userSelect: 'none', height: '100px', width: '100%', fontSize: '15px', fontFamily: 'Helvetica', marginBottom: '5px', textAlign: 'center', position: 'relative',top:'110px' }}>
    <span style={{ fontWeight: 'bold', display: 'block' }}>Name: {username}</span>
    <span>Score: {score}</span>
  </div>

  <div style={{ marginBottom: '5px', textAlign: 'center', position: 'relative',top:'180px',left:'-5px' }}>
    <SignOut onSignOut={handleSignOut} />
  </div>
  <div style={{ marginBottom: '5px', textAlign: 'center', position: 'relative',top:'-140px',left:'30px' }}>
    <Link to="/mathintro">
      <img src={home} alt="Home" style={{ width: '60px', height: '60px' }} />
    </Link>
  </div>
  <div style={{ position: 'relative', marginBottom: '5px',top:'5px',left:'-8px' }}>
    <button style={leadbuttonStyle} onClick={handleLeaderboardToggle}>
      <img src={leadericon} alt="Leaderboard" style={{ width: '40px', height: '40px' }} />
    </button>
  </div>
  {showLeaderboard && (
    <div style={{ padding: '5px', border: '1px solid #ccc', borderRadius: '10px', textAlign: 'center' }}>
      <h3> top Mathswipers </h3>
      <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
        {leaderboard.map((user, index) => (
          <li key={user.id} style={{ marginBottom: '5px' }}>
            {index + 1}. {user.name} - Score: {user.score}
          </li>
        ))}
      </ul>
    </div>
  )}
  <div style={{ marginBottom: '5px', position: 'relative' ,top:'400px'}}>
    <textarea value={comment} onChange={handleCommentChange} placeholder="Enter your comment here" style={{ width: '100%', marginBottom: '5px' }} />
    <button style={{ ...buttonStyle, width: '100%' }} onClick={handleCommentSubmit}>
      Submit Comment
    </button>
  </div>
  <div style={{ marginBottom: '5px', textAlign: 'center', position: 'relative' }}>
    <Player ref={itemref} autoplay={true} loop={true} src={item} />
  </div>
</div>

  </div>
) : (
  // Your non-mobile view here


  // Your non-mobile view here


        <div
          className="side-dashboard2"
          style={{ display: 'flex', flexDirection: 'column', width: '333px' }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
    <div style={{ display: 'flex',flexDirection:'column', position: 'fixed', alignItems: 'center',zIndex:5}}>
  <div style={{ marginRight: '10px',top:'25px' ,left:'35px',position:'fixed'}}>
    <Link to="/mathintro">
      <img
        src={home}
        alt="Home"
        style={{ width: '60px', height: '60px', margin: '10px' }}
      />
    </Link>
  </div>

  <div
    style={{
      fontFamily: 'Helvetica',
      fontSize: '17px',
      flexGrow: 1,
      position:'fixed',
      top:'10px',
      left:'100px'
    }}
    className="dashboard-links"
  >
    <ul style={{ listStyleType: 'none', padding: 0, margin: 5 }}>
      <li
        style={{
          backgroundColor: '#43C0FF',
          borderRadius: '20px',
          width: '150px',
          height: '60px',
          color: 'black',
          padding: '10px 20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
                <img src={sections} alt="Sections" style={{ width: '40px', height: '40px' }} />

        <Link to="/title" style={{ textDecoration: 'none', color: 'black',fontWeight:'bolder',marginTop:'17px' }}>
          Sections
        </Link>
      </li>

      <li
        style={{
          backgroundColor: '#43C0FF',
          borderRadius: '20px',
          width: '150px',
          height: '60px',
          color: 'black',
          padding: '10px 20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
                        <img src={video} alt="Sections" style={{ width: '40px', height: '40px' }} />

        <Link to="/mlearn" style={{ textDecoration: 'none', color: 'black',fontWeight:'bolder',marginTop:'17px' }}>
          Lesson
        </Link>
      </li>
    </ul>
  </div>
</div>


      <div
  className="signoutbox"
  style={{
    userSelect: 'none',
    width:'250px',
    height:'100px',
    top:'220px',
    left:'40px',
    fontFamily: 'Helvetica',
    position: 'fixed', // Use relative positioning for the container
    padding: '10px', // Optional padding for spacing
    border: "3px solid black",
    backgroundColor: "white",
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' , // Added box shadow
    color:'black',
    borderRadius:'20px',
    display:'flex',
    position:'fixed'
  }}
>
  <div style={{position:'fixed'}}>
  <span
    style={{
      position: 'fixed'
   ,marginTop:'10px',
      fontWeight:'bold'
    }}
  >
    Name: {username}
  </span>
  <span
    style={{
      position: 'fixed',
      marginTop:'40px',
      fontWeight:'bold'

    }}
  >
    Score: {score}
  </span>
  </div>
  <div style={{width:'100px',height:'100px',position:'fixed',marginLeft:'90px',top:'400px'}}>
  <Player ref={itemref} autoplay={true} loop={true} src={item}  />

  </div>
 
</div>

          <div style={{position:'fixed',top:'280px',left:'130px'}}>
            <SignOut onSignOut={handleSignOut} />
          </div>
          <div>
             {/* <NotificationIcon message={message} /> */}
          </div>
          <div style={{ fontFamily: 'helvetica', position:'relative', fontSize: '20px' }} className="dashboard-links">
     

          </div>
          <div style={{position:'fixed',top:'100px',left:'40px'}}>
          <button style={leadbuttonStyle} onClick={handleLeaderboardToggle}>
          <img src={leadericon} alt="Sections" style={{ width: '40px', height: '40px',}} />

      </button>
            </div>
        
          {showLeaderboard && (
        <div style={{...leaderboardBoxStyle,marginTop:'100px',position:'fixed'}}>
          <h3>Top  Performers</h3>
          <ul>
            {leaderboard.map((user, index) => (
              <li key={user.id}>
                {index + 1}. {user.name} - Score: {user.score}
              </li>
            ))}
          </ul>
        </div>
      )}
          {!authenticate && (
 <div style={{position:'fixed',left:'60px',bottom:'65px'}}>
 <textarea
   value={comment}
   onChange={handleCommentChange}
   placeholder="Enter your comment here"
 />
 <button style={{...buttonStyle,position:'fixed',top:'680px',left:'100px'}} onClick={handleCommentSubmit}>Submit Comment</button>
</div>

          )}
       
      
        </div>
      )}
    </div>
  );
};

export default PracticeDashboard;
