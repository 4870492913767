import React, { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
//import { ProgressBar } from 'react-bootstrap';
import ProgressBar from "./LoadingPages/ProgressBar";
import { Player } from '@lottiefiles/react-lottie-player';
import './title.css';
import welcome from './jsons/welcome.json';
import bg from './jsons/bga.json';
import portrait from './jsons/portraitbg.json';
import mathintro from './jsons/mathintro.json';

const Title = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showPopup, setShowPopup] = useState(false);
    const windowref = useRef(null);

    const introref = useRef(null);
    const listposition = {
        margin: '15px', 
        fontSize:'20px',
    };
    const listpositionlevel1 = {
        margin: '15px', 
        fontSize:'25px',
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    const handleLinkClick = (e) => {
        e.preventDefault();
        setShowPopup(true);
        setTimeout(() => {
            setShowPopup(false);
        }, 3000);
    };

    return (
        <div style={{ userSelect: 'none' }}>
            {isMobile ? (
                <div id="main" ref={windowref} style={{height:'100vh',width:'100vh',overflow:'hidden',position:'fixed' }}>
               <div style={{position:'fixed',top:'50px'}} >
            <Player  ref={introref} autoplay={true} loop={true} src={portrait} intermission={3}     />

            </div>
                <div style={{ fontFamily: 'Helvetica', position: 'fixed', top: '60px' }}>
                        <div style={{ alignSelf: 'center', marginBottom: '26px' }}>
                            <h2>Topics</h2>
                        </div>
                        <ul style={{ ...listpositionlevel1 }} className="main-list">
                            <li>
                                <div className="circle" style={{ ...listpositionlevel1 }}></div>
                                <p>Algebra</p>
                                <ul className="sub-list" style={{ ...listposition, marginTop: '50px', marginRight: '0px' }}>
                                    <li>
                                        <div className="circle" style={{ ...listposition }}>
                                        </div>
                                        <Link to="/mlearn" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <p> Lesson 1: Equations</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="circle" style={{ ...listposition }}>
                                        </div>
                                        <Link to="/mlearn3" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <p>Equation Scenario</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="circle3" style={{ ...listposition }}>
                                        </div>
                                        <Link to="/mtrain" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <p>Challenge 1</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="circle" style={{ ...listposition }}>
                                        
                                        </div>
                                        <Link to="/mlearn2" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <p>Quadratic </p>
                                        </Link>
                                    </li>
                                   
                                    <li>
                                        <div className="circle" style={{ ...listposition }}>
                                        
                                        </div>
                                        <Link to="/joingame" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <p>Multiplayer</p>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                          
                        </ul>
                    </div>
                </div>
            ) : (
                <div style={{ userSelect: 'none',display:'flex' }}>
                    <div className='introbox' >
       <Player  ref={introref} autoplay={true} loop={true} src={mathintro} intermission={3}     />

       </div>
                   
                    <div className="title" style={{ fontFamily: 'Helvetica' }}>
                        <div style={{ alignSelf: 'center', marginBottom: '-46px' }}>
                            <h2>Topics</h2>
                        </div>
                        <ul style={{ ...listpositionlevel1 }} className="main-list">
                            <li>
                                <div className="circle" style={{ ...listpositionlevel1 }}></div>
                                <p>Algebra</p>
                                <ul className="sub-list" style={{ ...listposition, marginTop: '50px', marginRight: '0px' }}>
                                    <li>
                                        <div className="circle" style={{ ...listposition }}>
                                        </div>
                                        <Link to="/mlearn" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <p> Lesson 1: Equations</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="circle" style={{ ...listposition }}>
                                        </div>
                                        <Link to="/mlearn2" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <p> Lesson 2: Quadratic Equations</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="circle3" style={{ ...listposition }}>
                                        </div>
                                        <Link to="/mtrain" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <p>Game 1: Equations</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="circle3" style={{ ...listposition }}>
                                        </div>
                                        <Link to="/mlearn3" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <p> Application of Equations</p>
                                        </Link>
                                    </li>
                                   
                                </ul>
                            </li>
                           
                        </ul>
                    </div>
                  
                </div>
            )}

            {showPopup && (
                <div className="popup" style={popupStyle}>
                    <div className="popup-content" style={popupContentStyle}>
                        <h3>Notice</h3>
                        <p>This content is not yet available.</p>
                    </div>
                </div>
            )}
        </div>
    );
}

const popupStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '300px',
    padding: '20px',
    backgroundColor: 'white',
    border: '2px solid blue',
    borderRadius: '20px',
    textAlign: 'center',
    zIndex: '1000',
};

const popupContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

export default Title;
