import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { Draggable } from "gsap/all";
import wrong from '../jsons/wrongmove.json';
import divide from '../jsons/Division.json';
import '../index.css';
import sub from '../jsons/subtraction.json';
import { getFirestore, doc, updateDoc, increment } from 'firebase/firestore';
import { app } from '../Firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useGesture } from 'react-use-gesture';
import congs from '../jsons/congs.json';
import { Player } from "@lottiefiles/react-lottie-player";
import sqrt from '../jsons/sqrt.json';
import hint1 from '../jsons/hintsq.json';
import mainhint from '../jsons/hint2.json';
import stickfoot1 from '../jsons/stickfoot1.json';
import stickfoot2 from '../jsons/stickfoot2.json';
import { Container, Row, Col,Card } from 'react-bootstrap';

const Component8 = ({ n1,sendDataToParent }) => {
  const n1Ref = useRef(null);
  const xref = useRef(null);
  const sqrtref = useRef(null);
  const hinterref = useRef(null);
  const [hinters, sethinters] = useState(hint1);
  const hintref = useRef(null);
  const [showhint, setshowhint] = useState(false);
  const [M1, setM1] = useState(0);
  const [Mn, setMn] = useState(0);
  const playsqrt = useRef(null);
  const playsqrt2 = useRef(null);
  const [explaintext, setexplaintext] = useState('square root both sides');
  const [answered, setanswered] = useState(false);
  const stickfootref = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const explainref = useRef(null);
  const [stickfoot, setstickfoot] = useState(stickfoot1);

  const sqrtref2 = useRef(null);
  const auth = getAuth();
  const currentUser = auth.currentUser;
   const currentUserId = currentUser.uid;
   const db = getFirestore();
  const [longPress, setLongPress] = useState(false);
  const longPressDuration = 500; // 500ms (half a second)
  let pressTimeout = null;


  
  const handleMouseDown = () => {
    pressTimeout = setTimeout(() => {
      setLongPress(true);
      gsap.to(xref.current, { scale: 1.4,duration: 0.4, ease: "power2.inOut" });
      console.log('long pressed');
      gsap.to(explainref.current, { opacity:100, duration:0.3 });

      gsap.to(sqrtref.current, { scale:0.5, y:-50, duration:0.3 });
      gsap.to(sqrtref2.current, { scale:0.5, y:-50, duration:0.3 });
      setTimeout(() => {
        sendData();
      }, 1000);
      if(playsqrt.current){
        playsqrt.current.play();
      }
      if(playsqrt2.current){
        playsqrt2.current.play();
      }
    }, longPressDuration);

setTimeout(() => {
    gsap.to(sqrtref.current, { opacity:0,  duration:0.5 });
    gsap.to(sqrtref2.current, {  opacity:0,  duration:0.5 });
    n1Ref.current.textContent = Math.sqrt(n1)
    setstickfoot(stickfoot2);
    setTimeout(() => {
      setstickfoot(stickfoot1);
    }, 3000);
    incrementM1();
    //toggle span
    gsap.to(xref.current.querySelector('span'), { opacity: 0, duration: 0.5 });

}, 2000);

  };

  const handleMouseUp = () => {
    clearTimeout(pressTimeout);
  };
  const hovercharacter = () => {
    gsap.to(xref.current, { color: 'red' });
};

const leavecharacter = () => {
    gsap.to(xref.current, { color: 'black' });
};
const updateM1InFirestore = async (newM1Value) => {
  try {
    const userDocRef = doc(db, 'users', currentUserId);
    await updateDoc(userDocRef, { M1: increment(1) });
    console.log('M1 updated successfully');
  } catch (error) {
    console.error('Error updating M1:', error);
  }
};

const incrementM1 = () => {
  setM1(prevM1 => {
    const newM1 = prevM1 + 1;
    updateM1InFirestore(newM1).then(() => {
      // code to be executed after the update is complete
    }).catch(error => {
      console.error('Error updating M1:', error);
    });
    return newM1;
  });

};
const decreaseM1 = () => {
  setM1(prevM1 => {
    const newM1 = prevM1 - 1;
    decreaseM1InFirestore(newM1).then(() => {
      // code to be executed after the update is complete
    }).catch(error => {
      console.error('Error updating M1:', error);
    });
    return newM1;
  });

};
const decreaseM1InFirestore = async (newM1Value) => {
  try {
    const userDocRef = doc(db, 'users', currentUserId);
    await updateDoc(userDocRef, { M1: increment(-1) });
    console.log('M1 updated successfully');
  } catch (error) {
    console.error('Error updating M1:', error);
  }
};
const handleResize = () => {
  setIsMobile(window.innerWidth <= 768);
};


useEffect(() => {
    const sqrt1position = xref.current.getBoundingClientRect().top;
    const sqrt2position = xref.current.offsetLeft;
    const sqrt3position = xref.current.offset;

    gsap.to(sqrtref.current, { x: sqrt2position-130,
     y:100, duration:0 });

     gsap.to(sqrtref2.current, { x: sqrt2position+20,
        y:100, duration:0 });
})

const sendData = () => {
  const data = true;
  sendDataToParent(data);
};
 
    const enablehint = () => {
  
      decreaseM1();
      setshowhint(true);
     // gsap.to(one, { backgroundColor: 'blue', duration: 1 });
      setTimeout(() => {
  playhinter();
  
  }, 500);
  
  setTimeout(() => {
    setshowhint(false);
  }, 5000);
  
  
    };

    //hinter    
     const playhinter = () => {
   
      if(hinterref.current){
        hinterref.current.play();
      }
  
    };
  
  return (
    <>
    
    {isMobile?(
       <Container
       className="d-flex justify-content-center align-items-center"
       style={{ minHeight: "100vh", marginBottom: "10vh" }}
     >
         <Row className="justify-content-center flex-wrap">
            <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
            <Card style={{ width: '300px' ,height:'300px'}}>
            <Card.Body className="text-center">
            <div>
      <div ref={explainref} style={{opacity:0, display:'absolute',userSelect:'none'}}>
        {explaintext}
      </div>
         <div ref={sqrtref} style={{zIndex:1,position:'fixed' }}>
        <Player  ref={playsqrt} autoplay={false} keepLastFrame={true} loop={false} src={sqrt} />
        </div>
        <div ref={sqrtref2} style={{zIndex:1,position:'fixed' }}>
        <Player  ref={playsqrt2} autoplay={false} keepLastFrame={true} loop={false} src={sqrt} />
        </div>
      <div style={{ display: 'flex',zIndex:3, userSelect:'none',fontFamily: 'helvetica', fontSize: '40px',cursor: 'pointer' }}>
        <div ref={xref} style={{margin:'20px'}} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseEnter={hovercharacter} onMouseLeave={leavecharacter}> 
          x <span style={{position:'relative',fontSize:'15px', bottom:'20px'}}>2</span>
        </div>
        <div style={{margin:'20px'}}> = </div>
        <div ref={n1Ref} style={{margin:'20px'}} > {n1} </div>
      </div>
     
      <div style={{zIndex:5,scale:'0.3',position:'fixed',left:'750px',top:'365px'}}>

            {showhint && (
              <div style={{fontFamily:'helvetica',fontSize:'100px',color:'black', scale:'2' // Added CSS property to prevent wrapping
            }}>
               
               <Player ref={hinterref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={hinters}   // or setbball(bballp)
 />
                 </div>
                 
            )}
               <div onClick={enablehint}>
        <Player ref={hintref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={mainhint}   // or setbball(bballp)
 />
        </div>
        </div>
    </div>
            </Card.Body>

    
    </Card>
    </Col>
    </Row>
    </Container>

    ):(
      <div style={{top:'350px', position:'fixed'}}>
      <div ref={explainref} style={{opacity:0, display:'absolute',userSelect:'none'}}>
        {explaintext}
      </div>
         <div ref={sqrtref} style={{zIndex:1,position:'fixed' }}>
        <Player  ref={playsqrt} autoplay={false} keepLastFrame={true} loop={false} src={sqrt} />
        </div>
        <div ref={sqrtref2} style={{zIndex:1,position:'fixed' }}>
        <Player  ref={playsqrt2} autoplay={false} keepLastFrame={true} loop={false} src={sqrt} />
        </div>
      <div style={{ display: 'flex',zIndex:3, userSelect:'none',fontFamily: 'helvetica', fontSize: '40px',cursor: 'pointer' }}>
        <div ref={xref} style={{margin:'20px'}} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseEnter={hovercharacter} onMouseLeave={leavecharacter}> 
          x <span style={{position:'relative',fontSize:'15px', bottom:'20px'}}>2</span>
        </div>
        <div style={{margin:'20px'}}> = </div>
        <div ref={n1Ref} style={{margin:'20px'}} > {n1} </div>
      </div>
      <div style={{scale:'0.7',position:'fixed',left:'200px',top:'-80px'}}>
      <Player ref={stickfootref} autoplay={true} loop={true} keepLastFrame={true} src={stickfoot}   // or setbball(bballp)
 />

      </div>
      <div style={{zIndex:5,scale:'0.3',position:'fixed',left:'750px',top:'365px'}}>

            {showhint && (
              <div style={{position:'fixed',fontFamily:'helvetica',fontSize:'100px',left:'500px',bottom:'40px',color:'black', scale:'2' // Added CSS property to prevent wrapping
            }}>
               
               <Player ref={hinterref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={hinters}   // or setbball(bballp)
 />
                 </div>
                 
            )}
               <div onClick={enablehint}>
        <Player ref={hintref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={mainhint}   // or setbball(bballp)
 />
        </div>
        </div>
    </div>
    )}
</>
  );
};

export default Component8;
