import React, { useState, useEffect } from "react";
import { Navbar, Button, Modal } from 'react-bootstrap';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import Signup from './Signupp';
import om2 from './jsons/images/om2.png';
import Login from './Login2';
const auth = getAuth();
const db = getFirestore();

const PhysicsHeader = () => {
  const [username, setUsername] = useState("Username");
  const [marks, setMarks] = useState(0);
  const [mep, setmep] = useState(0);
  const [mdp, setmdp] = useState(0);
  const [mkp, setmkp] = useState(0);
  const [mef, setmef] = useState(0);
  const [mdf, setmdf] = useState(0);
  const [mkf, setmkf] = useState(0);
  const [passed, setpassed] = useState(0);
  const [failed, setfailed] = useState(0);





  <div className="container my-5">
  <h2 className="text-center mb-4">Journey & History</h2>
  
  {/* ESS - Electricity Study Simulator */}
  <div className="row align-items-center mb-4">
    <div className="col-md-6">
      <h5>Electricity Study Simulator (ESS)</h5>
      <p>
        Scisteps began as <strong>ESS (Electricity Study Simulator)</strong>, a final year project 
        at the university in 2022. The project aimed to simplify the study of electricity concepts 
        through interactive simulations, marking the foundation of what would become Scisteps.
      </p>
    </div>
    <div className="col-md-6">
      <img 
        src={om2} 
        alt="Electricity Study Simulator" 
        className="img-fluid rounded"
      />
    </div>
  </div>

  {/* Mathswipe */}
  <div className="row align-items-center mb-4">
    <div className="col-md-6 order-md-2">
      <h5>Mathswipe</h5>
      <p>
        In 2023, <strong>Mathswipe</strong> was introduced as an interactive math learning 
        platform. It featured engaging lessons and practice tests for students. The project was 
        showcased at the <strong>UgDevSummit 2023</strong>, gaining recognition for its innovative 
        approach to education.
      </p>
    </div>
    <div className="col-md-6 order-md-1">
      <img 
        src={om2} 
        alt="Mathswipe" 
        className="img-fluid rounded"
      />
    </div>
  </div>

  {/* Scisteps */}
  <div className="row align-items-center mb-4">
    <div className="col-md-6">
      <h5>Scisteps</h5>
      <p>
        Building on the success of ESS and Mathswipe, <strong>Scisteps</strong> was born as a 
        comprehensive science education platform. It integrates the best features of its 
        predecessors, providing interactive simulations, experiments, and engaging content 
        across various science subjects.
      </p>
    </div>
    <div className="col-md-6">
      <img 
        src={om2} 
        alt="Scisteps" 
        className="img-fluid rounded"
      />
    </div>
  </div>
</div>







  const [userUID, setUserUID] = useState(null);
  const [showSignup, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [authenticate, setAuthenticate] = useState(true);

  useEffect(() => {
    const fetchUserData = async (user) => {
      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUsername(userDoc.data().name);
          setUserUID(user.uid);
          setShowSignup(false);
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUsername('Guest');
      }
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticate(false);
        fetchUserData(user);
      } else {
        setAuthenticate(true);
      }
    });
  }, []);

 

  useEffect(() => {
    let unsubscribe;

    if (userUID) {
      const userDocRef = doc(db, 'usersp', 'PHY');
      unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setmep(docSnapshot.data().mEp || 0);
          setmdp(docSnapshot.data().mDp || 0);
          setmkp(docSnapshot.data().mKp || 0);
        
          setmef(docSnapshot.data().mEf || 0);
          setmdf(docSnapshot.data().mDf || 0);
          setmkf(docSnapshot.data().mKf || 0);
          setfailed(mef+mdf+mkf);
          setpassed(mep+mdp+mkp);
          setTimeout(() => {
            setMarks(passed);

          }, 100);
          console.error('total score is ',marks);

        //  setfail(docSnapshot.data().Mn || 0);
        } else {
          console.error('failed to show marks');
        }
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [userUID,mep,mdp,mkp,mef,mdf,mkf]);


  const handleShowSignup = () => setShowSignup(true);
  const handleShowLogin = () => setShowLogin(true);
  const handleCloseSignup = () => setShowSignup(false);
  const handleCloseLogin = () => setShowLogin(false);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setAuthenticate(true);
      setUsername("Username");
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <>
    <Navbar
  expand="lg"
  fixed="top"
  style={{
    height: "95px",
    backgroundColor: "#f8f9fa",
    zIndex: 1000,
    borderBottom: "2px solid #ccc",
    padding: "10px 20px",
  }}
>
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    }}
  >
    {/* Left Side: Navigation Links */}
    <div style={{ 
  display: 'flex', 
  alignItems: 'center',
  gap: '20px' /* Adds spacing between Scisteps and About */
}}>
  <Navbar.Brand 
    href="/subjectselect" 
    style={{ 
      fontSize: "1.5rem", 
      fontWeight: "bold", 
      borderRight: "2px solid black", 
      paddingRight: "30px" 
    }}
  >
    Scisteps
  </Navbar.Brand>
  <Navbar.Brand 
    href="/about" 
    style={{ 
      fontSize: "1.5rem", 
      fontWeight: "bold", 
      paddingLeft: "10px" 
    }}
  >
    About
  </Navbar.Brand>
</div>


    {/* Right Side: Total Score & Auth Buttons */}
    <div style={{ display: "flex", alignItems: "center" }}>
      <span
        style={{
          marginRight: "30px",
          fontSize: "1.2rem",
          whiteSpace: "nowrap",
        }}
      >
        Total Score: {marks}
      </span>
      {authenticate ? (
        <>
          <Button
            variant="primary"
            onClick={handleShowSignup}
            style={{ marginRight: "10px" }}
          >
            Signup
          </Button>
          <Button variant="secondary" onClick={handleShowLogin}>
            Login
          </Button>
        </>
      ) : (
        <>
          <span
            style={{
              marginRight: "15px",
              fontSize: "15px",
              marginBottom: "10px",
              whiteSpace: "nowrap",
            }}
          >
            Welcome, {username}!
          </span>
          <Button variant="danger" onClick={handleSignOut}>
            Sign Out
          </Button>
        </>
      )}
    </div>
  </div>
</Navbar>


      {/* Signup Modal */}
      <Modal show={showSignup} onHide={handleCloseSignup} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Sign Up</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
          <Signup onClose={handleCloseSignup} />
        </Modal.Body>
      </Modal>

      {/* Login Modal */}
      <Modal show={showLogin} onHide={handleCloseLogin} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "20px" }}>
          <Login onClose={handleCloseLogin} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PhysicsHeader;
