import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { Draggable } from "gsap/all";
import { Player } from "@lottiefiles/react-lottie-player";
import './Component.css';
import firebase from 'firebase/app';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, updateDoc, increment } from 'firebase/firestore';
import { app } from '../Firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import hint from '../jsons/numer.json';
import hint1 from '../jsons/denom.json';
import hinter2 from '../jsons/hinter2.json';
import mainhint from '../jsons/hint2.json';
import dragd from '../jsons/dragde.json';
import { Card,Container, Row, Col, CardBody } from 'react-bootstrap';

gsap.registerPlugin(Draggable);

const Component6  = ({ C1, C2, C3, C4, sign1, sign2, sendDataToParent }) => {
    const c1ref = useRef(null);
    const c2ref = useRef(null);
    const c3ref = useRef(null);
    const c4ref = useRef(null);
    const equalsref = useRef(null);
    const rightsign = useRef(null);
    const firstsign = useRef(null);
    const secondsign = useRef(null);
    const leftsign = useRef(null);
    const extrarightref = useRef(null);
    const extraleftref = useRef(null);
    const hintref = useRef(null);
    const hinterref = useRef(null);
    const [showvariable1, setshowvariable1] = useState(true); 
    const [showvariable2, setshowvariable2] = useState(true);
    const [variable1, setvariable1] = useState(C1); 
    const [variable2, setvariable2] = useState(C3); 
    const [userUID, setUserUID] = useState(null); // Store the user UID
    const [explaintext, setexplaintext] = useState('square root both sides');
    
    const explainref = useRef(null);

    const [answered, setanswered] = useState(false);

    const [sign3, setsign3] = useState('-'); 
    const [sign4, setsign4] = useState('+'); 
    const [thirdclick, setthirdclick] = useState(false); 
    const [twofirst, settwofirst] = useState(false); 
    const [threefirst, setthreefirst] = useState(false);
    const [M1, setM1] = useState(0);
    const [Mn, setMn] = useState(0);
    const [uM1, setuM1] = useState(null);
  const [uMn, setuMn] = useState(null);
  const [showhint, setshowhint] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [hinters, sethinters] = useState(hint1);
    const auth = getAuth();
   const currentUser = auth.currentUser;
    const currentUserId = currentUser.uid;
    const db = getFirestore();

    useEffect(() => {
      

   
        gsap.to(c2ref, { backgroundColor: 'yellow', duration: 1.5 });
  
 
        gsap.to(c2ref, { backgroundColor: 'yellow', duration: 1.5 });

    
      
 
      
 }, [c2ref,showhint]);


    const incrementM1 = () => {
        setM1(prevM1 => {
          const newM1 = prevM1 + 1;
          updateM1InFirestore(newM1).then(() => {
            // code to be executed after the update is complete
          }).catch(error => {
            console.error('Error updating M1:', error);
          });
          return newM1;
        });
    
      };
      const incrementuM1 = () => {
        setuM1(uM1 => {
          
          const newuM1 = uM1 + 1;
          //onUpdateUM1(newuM1); // Call the callback prop
          return newuM1;
        });
      };
    
      const incrementuMn = () => {
        setuMn(uMn => {
          const newuMn = uMn + 1;
         // onUpdateUMn(newuMn); // Call the callback prop
          return newuMn;
        });
      };
    
      const updateM1InFirestore = async (newM1Value) => {
      try {
        const userDocRef = doc(db, 'users', currentUserId);
        await updateDoc(userDocRef, { M1: increment(1) });
        console.log('M1 updated successfully');
      } catch (error) {
        console.error('Error updating M1:', error);
      }
    };
    
      
    
      const incrementMn = () => {
        setMn(prevMn => {
          const newMn = prevMn + 1;
          updateMnInFirestore(newMn).then(() => {
            // code to be executed after the update is complete
          }).catch(error => {
            console.error('Error updating Mn:', error);
          });
          return newMn;
        });
      };
      
      
    
      const updateMnInFirestore = async (newMnValue) => {
        try {
          const userDocRef = doc(db, 'users', currentUserId);
          await updateDoc(userDocRef, { Mn: increment(1) });
          console.log('Mn updated successfully');
        } catch (error) {
          console.error('Error updating Mn:', error);
        }
      };
      
      const thebase = {
        position: 'fixed',
        display: "flex",
        alignItems: "center",
        fontSize: "40px",
        color:"#585757",
        fontFamily:"Helvetica",
        marginTop:'17px',
        userSelect:'none'
        
    };
    const thebase2 = {
      position: 'fixed',
      display: "flex",
      alignItems: "center",
      fontSize: "20px",
      color:"#585757",
      fontFamily:"Helvetica",
      marginTop:'17px',
      userSelect:'none'
      
  };
    const digitposition = {
        position: "relative",
        left: "-60px",
        bottom: "-50px",
        padding: "10px",
        fontSize: '30px',
        color: 'black',
        userSelect: 'none',
        backgroundColor: "white",
        fontFamily: 'Helvetica',
        fontWeight:'bold',
        border: "1px solid #000",
        margin:'8px'

    };
    const borderless = {
        position: "relative",
        left: "-60px",
        bottom: "-50px",
        padding: "10px",
        fontSize: '30px',
        color: 'black',
        userSelect: 'none',
        margin:'8px'
        // border: "1px solid #000"
    };
    const equalposition = {
        position: "relative",
        left: "-60px",
        bottom: "-50px",
        padding: "20px",
        fontSize: '35px',
        color: 'red',
        userSelect: 'none',
        margin:'8px'

    };
    const signposition = {
        position: "relative",
        left: "-60px",
        bottom: "-50px",
        padding: "20px",
        fontSize: '35px',
        color: 'blue',
        userSelect: 'none',
        fontFamily: 'Helvetica',
        fontWeight:'bold',
        margin:'8px'

    };
  
    const playhinter = () => {
   
      if(hinterref.current){
        hinterref.current.play();
      }
  
    };
  
    const enablehint = () => {
  
      decreaseM1();
      setshowhint(true);
     // gsap.to(one, { backgroundColor: 'blue', duration: 1 });
      setTimeout(() => {
  playhinter();
  
  }, 500);
  
  setTimeout(() => {
    setshowhint(false);
  }, 5000);
  
  
    };


 


    const clicktwice = () => {
      console.log("doubleclicked");
      incrementM1();
     setanswered(true);
     gsap.fromTo(
      explainref.current,
      { opacity: 0 },
      { opacity: 1, duration: 1 } // Animation for revealing text
  );

      const newValueElement2 = document.createElement("div");
      newValueElement2.textContent = C2; 
      newValueElement2.style.position = "fixed";
    //  newValueElement2.style.top = "580px";
      newValueElement2.style.padding = "10px";
      newValueElement2.style.color = "#585757";
      newValueElement2.style.fontSize = "20px";
      newValueElement2.style.zIndex = "5";
      setexplaintext(`Multiplying both sides by ${C2}`);

      //newValueElement2.style.left = "560px";
      newValueElement2.style.top = `${c1ref.current.getBoundingClientRect().top-10}px`;

     newValueElement2.style.left = `${c1ref.current.getBoundingClientRect().left-100}px`;
      newValueElement2.style.cursor = "move";
      document.body.appendChild(newValueElement2);
     // const referenceElement = document.querySelector('#thisid'); // Replace with your specific div's selector
    
    // Insert the new div after the reference element
    
    // If referenceElement is not found, append to body as default
    document.body.appendChild(newValueElement2);
    const multi = document.createElement("div");
    
      multi.textContent = "x"; // Set the text content to the value of 'x'
      multi.style.position = "fixed";
      //multi.style.top = " 350px";
      multi.style.padding = "10px";
      multi.style.zIndex = "5";
       multi.style.fontSize = "20px";
       multi.style.fontFamily = "Helvetica";

       multi.style.top = `${c1ref.current.getBoundingClientRect().top +10}px`; // Position it below the 'x' element

       multi.style.left = `${c1ref.current.getBoundingClientRect().left -40}px`; // Position it below the 'x' element
       multi.style.cursor = "move";
     document.body.appendChild(multi);
     sethinters(dragd);
     
     //new multi
     const multi2 = document.createElement("div");
     
      multi2.textContent = "x"; // Set the text content to the value of 'x'
      multi2.style.position = "absolute";
      multi2.style.padding = "10px";
      multi2.style.color = "blue";
       multi2.style.fontSize = "30px";
       multi2.style.zIndex = "5";
       multi2.style.top = `${c3ref.current.getBoundingClientRect().top + 10}px`; // Position it below the 'x' element

       multi2.style.left = `${c3ref.current.getBoundingClientRect().right + 10}px`; // Position it below the 'x' element
       multi2.style.cursor = "move";
     document.body.appendChild(multi2);
     const m22 = c2ref.current;
      const draggableXValueInstance = Draggable.create(newValueElement2, {
        type: "x",
        edgeResistance: 0.65,
        onDragEnd: function () {
          const xValue = parseFloat(this.target.textContent);
          const xValuePosition = this.target.getBoundingClientRect().left;
          const m3Position = c3ref.current.getBoundingClientRect().left;
    const p1 = c3ref.current.offsetLeft;
    const p2 = newValueElement2.offsetLeft;
   // const eq = equalsref.off
    const pos = p1 - xValuePosition;

          if (xValuePosition > m3Position) {
            incrementM1();
            sethinters(hint1);
            gsap.to(newValueElement2, { x: p1+200, duration: 1,ease: "power2.inOut"  });
          //  setcross(true);
            
            const newproduct = C3 * xValue;
            gsap.to(c1ref.current, { borderBottom: "0px", duration: 0.3, delay:2, ease: "power2.inOut" });

            setTimeout(() => {
              const new2 = C2 / xValue; // Perform division using the value of the dragged element
              c2ref.current.textContent = new2; 
              gsap.to(newValueElement2, { opacity: 0, duration: 0.3, ease: "power2.inOut" });
              gsap.to(multi, { opacity:  0, duration: 0.3, ease: "power2.inOut" });
              gsap.to(multi2, { opacity:  0, duration: 0.3, ease: "power2.inOut" });
    
              c3ref.current.textContent = newproduct;        
             }, 2000);
    
            setTimeout(() => {
              c3ref.current.textContent = newproduct;
            }, 2500);
    
    
            setTimeout(() => {
              gsap.to(m22, { opacity:  0, duration: 0.3, ease: "power2.inOut" });
            }, 2500);
    
    
            
    
    
      
    
    
    
            this.disable();
          
     
            // Update the text content of the '5' element
            this.disable();       }

            else {
              const ret = newValueElement2.offsetLeft - newValueElement2.offsetLeft;
              gsap.to(newValueElement2, { x: ret, duration: 1,ease: "power2.inOut"  });
              decreaseM1();
            }
        }
       
      });
    };

    const clickthrice = () => {
      console.log("doubleclicked");
      incrementM1();
      sethinters(dragd);
      setexplaintext(`Dividing both sides by ${C1}`);
      gsap.fromTo(
        explainref.current,
        { opacity: 0 },
        { opacity: 1, duration: 1 } // Animation for revealing text
    );
  
      const newValueElement3 = document.createElement("div");
      newValueElement3.textContent = C1; 
      newValueElement3.style.position = "fixed";
    //  newValueElement2.style.top = "580px";
    newValueElement3.style.padding = "10px";
    newValueElement3.style.color = "#585757";
    newValueElement3.style.fontSize = "20px";
    newValueElement3.style.zIndex = "5";

      //newValueElement2.style.left = "560px";
      newValueElement3.style.top = `${c1ref.current.getBoundingClientRect().top+50}px`;

      newValueElement3.style.left = `${c1ref.current.getBoundingClientRect().left}px`;
      newValueElement3.style.cursor = "move";
      document.body.appendChild(newValueElement3);
     // const referenceElement = document.querySelector('#thisid'); // Replace with your specific div's selector
    
    // Insert the new div after the reference element
    
    // If referenceElement is not found, append to body as default
    document.body.appendChild(newValueElement3);
    const multi = document.createElement("div");
    
    gsap.to(c1ref.current, { borderBottom: "5px", duration: 0.3,  ease: "power2.inOut" });

    
      const draggableXValueInstance = Draggable.create(newValueElement3, {
        type: "x",
        edgeResistance: 0.65,
        onDragEnd: function () {
          const xValue = parseFloat(this.target.textContent);
          const xValuePosition = this.target.getBoundingClientRect().left;
          const m3Position = c3ref.current.getBoundingClientRect().left;
          const C3Value = c3ref.current.textContent;

    const p1 = c3ref.current.offsetLeft;
    const p2 = newValueElement3.offsetLeft;
   // const eq = equalsref.off
    const pos = p1 - xValuePosition;

          if (xValuePosition > m3Position) {
            incrementM1();
            sendData();
            gsap.to(newValueElement3, { x: p1, duration: 1,ease: "power2.inOut"  });
          //  setcross(true);
            
            const newdivision = +C3Value / +C1;
            gsap.to(c1ref.current, { borderBottom: "0px", duration: 0.3, delay:2, ease: "power2.inOut" });
            setTimeout(() => {
              const new2 = C2 / xValue; // Perform division using the value of the dragged element
              c2ref.current.textContent = new2; 
              gsap.to(newValueElement3, { opacity: 0, duration: 0.3, ease: "power2.inOut" });
             // gsap.to(multi, { opacity:  0, duration: 0.3, ease: "power2.inOut" });
             // gsap.to(multi2, { opacity:  0, duration: 0.3, ease: "power2.inOut" });
             setshowvariable1(false);

              c3ref.current.textContent = newdivision;        
             }, 2000);
    
           
    
    
      
    
            
    
    
      
    
    
    
            this.disable();
          
     
            // Update the text content of the '5' element
            this.disable();       }
            else{
              decreaseM1();
            }
        }
      });
    };


    const sendData = () => {
      const data = true;
      sendDataToParent(data);
    };
  
    const decreaseM1InFirestore = async (newM1Value) => {
      try {
        const userDocRef = doc(db, 'users', currentUserId);
        await updateDoc(userDocRef, { M1: increment(-1) });
        console.log('M1 updated successfully');
      } catch (error) {
        console.error('Error updating M1:', error);
      }
    };

    const decreaseM1 = () => {
      setM1(prevM1 => {
        const newM1 = prevM1 - 1;
        decreaseM1InFirestore(newM1).then(() => {
          // code to be executed after the update is complete
        }).catch(error => {
          console.error('Error updating M1:', error);
        });
        return newM1;
      });
  
    };



      return (
        <div>
          {isMobile? (
                                  <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginBottom: '10vh' }}>
  <Row className="justify-content-center flex-wrap">
  <Col xs={10} md={4} lg={5} className="mb-4 d-flex justify-content-center">
  <Card style={{ width: 'auto' ,height:'300px'}}>
  <Card.Body className="text-center">

 <div >
 <div style={{ ...thebase2}}>
        <div ref={c1ref} onDoubleClick={clickthrice} style={{ padding: "10px",  userSelect: 'none', margin: "10px",borderBottom:"5px solid black" }}>
       {showvariable1 &&(
         <span>
           {C1}
         </span>
       )}
         
         y
        </div>
        <div ref={c2ref} onDoubleClick={clicktwice}  style={{  padding: "10px",  userSelect: 'none',  }}>{C2}</div>
        <div ref={equalsref} style={{ padding: "10px",  color: 'blue', userSelect: 'none', margin: "10px" }}>=</div>
        <div ref={c3ref} style={{ padding: "10px",  userSelect: 'none', margin: "10px" }}>{C3}</div>
        <div ref={rightsign} style={{ padding: "10px",  userSelect: 'none', color: 'blue', margin: "10px", opacity: 0 }}>-</div>
    </div>

  

</div>
</Card.Body>
</Card>
</Col>
<Col>
<Card>
  <CardBody>
  <div >
<div onClick={enablehint}>
<Player ref={hintref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={mainhint}   // or setbball(bballp)
/>

</div>
 {showhint && (
   <div style={{fontFamily:'helvetica',fontSize:'20px',color:'black',// Added CSS property to prevent wrapping
 }}>
    
    <Player ref={hinterref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={hinters}   // or setbball(bballp)
/>
      </div>
 )}

</div>
  </CardBody>
</Card>
</Col>
</Row>
</Container>

          ): (

            <div className="practicearea">
          
  <div ref={explainref} style={{opacity:0, display:'absolute',userSelect:'none'}}>
  {explaintext}
</div>
             
             
            <div style={{ ...thebase}}>
                   <div ref={c1ref} onDoubleClick={clickthrice} style={{ padding: "10px",  userSelect: 'none', margin: "10px",borderBottom:"5px solid black" }}>
                  {showvariable1 &&(
                    <span>
                      {C1}
                    </span>
                  )}
                    
                    y
                   </div>
                   <div ref={c2ref} onDoubleClick={clicktwice}  style={{ position:"relative" , left:"-60px",bottom:"-70px", padding: "10px",  userSelect: 'none',  }}>{C2}</div>
                   <div ref={equalsref} style={{ padding: "10px",  color: 'blue', userSelect: 'none', margin: "10px" }}>=</div>
                   <div ref={c3ref} style={{ padding: "10px",  userSelect: 'none', margin: "10px" }}>{C3}</div>
                   <div ref={rightsign} style={{ padding: "10px",  userSelect: 'none', color: 'blue', margin: "10px", opacity: 0 }}>-</div>
               </div>

               <div style={{zIndex:5,scale:'0.3',position:'fixed',left:'750px',top:'365px'}}>
        <div onClick={enablehint}>
        <Player ref={hintref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={mainhint}   // or setbball(bballp)
 />
        </div>
            {showhint && (
              <div style={{position:'fixed',fontFamily:'helvetica',fontSize:'20px',left:'550px',bottom:'40px',color:'black', scale:'3.5' // Added CSS property to prevent wrapping
            }}>
               
               <Player ref={hinterref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={hinters}   // or setbball(bballp)
 />
                 </div>
            )}

      </div>

        </div>
          )}
 
        </div>
      
    );


};

export default Component6;